<template>
    <div class="box" @click="goToUrl">
        <div class="count-active" v-if="icon">
            <img :src="icon" alt="box-icon" />
            <label>{{ count }}</label>
        </div>
        <div class="products-discard" v-else>
            <label>{{ count }}</label>
        </div>
        <div class="label">
            {{ label }}
        </div>
    </div>
</template>

<script>
export default {
    name: 'SmallWidget',
    props: {
        count: {
            type: Number,
            required: true
        },
        label: {
            type: String,
            required: true
        },
        icon: {
            type: String,
            required: false
        },
        url: {
            type: String,
            required: true
        }
    },
    methods: {
        goToUrl() {
            window.location.href = this.url
        }
    }
}
</script>

<style scoped>
.box {
    cursor: pointer;
    /* Add additional styles */
}
</style>
