<template>
  <div class="card">
    <div class="title-card">
      PRODUCTOS
      <button>
        <img
          src="/img/add-icon.svg"
          alt="add-icon"
          width="70%">
      </button>
    </div>
    <div class="container">
      <img
        src="https://api.labtowin.com//static/images/alexhead_06.svg"
        alt="img-andy"
        width="20%">
      <label>
        Sin alertas
      </label>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CardProducts',
}
</script>