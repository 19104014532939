<template>
  <div
    @click="$router.push(details.params.link)"
    class="box">
    <img src="/img/box-arrow.svg" alt="box-arrow">
    <div class="label">{{ name }}</div>
  </div>
</template>

<script>
export default {
  name: 'DirectAccessComponent',
  props: {
    details: {
      type: Object,
      required: true
    },
    name: {
      type: String,
      required: true
    }
  }
}
</script>