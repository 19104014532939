<template>
  <div class="tickets hide-scrollbar" :class="[$mq]">
    <!-- <div v-if="$mq.includes('desktop')" class="andyimg"></div> -->
    <div class="boxes hide-scrollbar">
      <DirectAccess
        v-if="config.configWidget.some((widget) => widget.id === 'label')"
        :details="config.configWidget.find((widget) => widget.id === 'label')"
        name="ETIQUETAS" />
      <DirectAccess
        v-if="config.configWidget.some((widget) => widget.id === 'traceability')"
        :details="config.configWidget.find((widget) => widget.id === 'traceability')"
        name="TRAZABILIDAD" />

      <div class="products-state">
          <SmallWidget :count="14" label="Timers activos" icon="/img/timer.svg" url="https://blue-my.labtowin.com/timers" />
          <SmallWidget :count="'06'" label="Productos a desechar" url="https://blue-my.labtowin.com/product" />
          <SmallWidget :count="'06'" label="Productos por producir a las 12:00" url="https://blue-my.labtowin.com/product-to-produce" />
          <SmallWidget :count="18" label="Productos en buen estado" icon="/img/check-circle.svg" url="https://blue-my.labtowin.com/good-products" />
      </div>

      <div class="products-state">
          <CardOperationalTimers />
          <CardProducts />
      </div>
    </div>
  </div>
</template>

<script>
import DirectAccess from '@/components/domain/dashboard/DirectAccess.vue'
import CardOperationalTimers from '@/components/domain/dashboard/CardOperationalTimers.vue'
import CardProducts from '@/components/domain/dashboard/CardProducts.vue'
import SmallWidget from '@/components/domain/dashboard/SmallWidget.vue'
import { mapState } from "vuex";

export default {
  name: 'Dashboard',
  components: { CardProducts, CardOperationalTimers, DirectAccess, SmallWidget },
  computed: {
    ...mapState('login', ['config'])
  }
}
</script>

<style lang="scss">
.zsiq_floatmain {
    display: none !important;
}

.tickets.landscape {
    .boxes {
        width: 100%;
    }
}

.tickets {
    @include display-flex();
    @include align-items(center);
    width: 100%;
    height: 100%;
    overflow: auto;

    .boxes {
        @include display-flex();
        @include justify-content(flex-start);
        @include align-content(flex-start);
        @include flex-wrap(wrap);
        padding: 60px 8px;
        width: 100%;
        height: 100%;

        .box {
            @include display-flex();
            @include justify-content(center);
            @include align-items();
            @include border-radius(5px);
            @include interaction();
            background-color: #fff;
            padding: 14px 20px;
            width: 300px;
            margin: 20px;

            .label {
                width: 156px;
                @include display-flex();
                @include justify-content(center);
                @include align-items(center);
                @include font-size(sm);
                font-family: $text-bold;
                color: $neutro-s80;
                padding-left: 20px;
            }

            img {
                width: 25px;
                height: 25px;
            }

            .count-active {
                display: flex;
                @include align-items(center);

                label {
                    font-size: 21px;
                    margin: 0px 0px;
                    color: #157d82;
                    font-weight: 600;
                }
            }

            .products-discard {
                border: 6px solid #bd1f1e;
                border-radius: 50%;

                label {
                    margin: 0px;
                    font-size: 20px;
                    padding: 21px 20px;
                    font-weight: 600;
                    color: #222222;
                }
            }

            &.helpcenter {
                .icon {
                    @include background($image: img('book.svg'), $position: center center, $size: 38px);
                }
            }
        }

        .card {
            @include display-flex();
            @include justify-content(center);
            @include align-items();
            @include border-radius(5px);
            @include interaction();
            flex-direction: column;
            background-color: #fff;
            width: 740px;
            margin: 20px;

            .title-card {
                padding: 15px 0px;
                background-color: #e2e2e2;
                position: relative;
                width: 100%;
                text-align: center;
                font-weight: 600;
            }

            .container {
                width: 100%;
                text-align: center;
                padding: 90px 0px;

                label {
                    text-align: center;
                    font-weight: 600;
                }
            }

            button {
                background-color: #1ba844;
                padding: 7px;
                border-radius: 6px;
                position: absolute;
                right: 14px;
                bottom: 8px;
            }
        }

        .products-state {
          display: flex;
        }
    }
}
</style>
